.social {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  min-width: 980px;
  margin: 0 auto;
  max-width: 1280px;
}

.column {
  flex: 1;
  margin: 10px;
}

.friend-list, .ranking, .game-section {
  flex: 1 1 33%;
  margin: 10px;
}

.friend-list h2, .ranking h2, .game-section h2 {
  margin-bottom: 10px;
}

.friend-list ul, .ranking ul, .game-section ul {
  list-style-type: none;
  padding: 0;
}

.friend-list li, .ranking li, .game-section li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.friend-list li:hover, .ranking li:hover, .game-section li:hover {
  background-color: #f0f0f0;
}

.friend-item, .ranking-item, .game-item {
  display: flex;
  align-items: center;
}

.friend-item i, .game-item i {
  margin-right: 15px; /* Increase space between icon and text */
}

.circle {
  width: 20px;
  height: 20px;
  background-color: grey;
  border-radius: 50%;
  margin-right: 10px;
}

.my-ranking {
  background-color: #d4edda;
}

.selected-friend, .selected-game {
  margin-top: 20px;
  font-weight: bold;
}

.ranking-filters {
  margin-bottom: 20px;
}

.ranking-filters label {
  display: block;
}

.ranking-filters select {
  margin-top: 10px;
  padding: 5px;
  font-size: 16px;
}

.chat-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.chat-popup {
  width: 50%;
  height: 50%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1001;
}

.chat-header {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background-color: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat-header h3 {
  margin: 0;
  font-size: 16px;
}

.chat-close {
  cursor: pointer;
  font-size: 16px;
}

.chat-history {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.chat-message {
  margin-bottom: 5px; /* Reduced margin */
  max-width: 70%;
  padding: 5px 10px; /* Reduced padding */
  border-radius: 10px;
  background-color: #e0e0e0;
}

.chat-message.user {
  align-self: flex-end;
  background-color: #4e6ef2;
  color: white;
}

.chat-message.other {
  align-self: flex-start;
  background-color: #f1f1f1;
  color: black;
}

.chat-footer {
  padding: 10px;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.chat-input {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.chat-buttons {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.chat-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;
}
