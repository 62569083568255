.inbox {
  padding: 20px;
  max-width: 760px;
  margin: 0 auto; /* Center the content */
}

.inbox h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.notifications-list {
  list-style: none;
  padding: 0;
}

.notification {
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.notification.friend-request {
  border-left: 4px solid #4ECDC4;
}

.notification.message {
  border-left: 4px solid #4e6ef2;
}

.notification.achievement {
  border-left: 4px solid #ffda44;
}

.notification.announcement {
  border-left: 4px solid #ffa07a;
}
