.header {
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

nav {
  display: flex;
  align-items: center;
}

nav a, nav button {
  color: white;
  text-decoration: none;
  margin: 0 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

nav a i, nav button i {
  margin-right: 5px;
}

nav a.active {
  color: #4ECDC4;
}

.search-container {
  display: flex;
  align-items: center;
  position: relative;
}

.search-container button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
}

.search-input {
  background-color: #333;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  margin-left: 5px;
  width: 150px;
}

.search-input::placeholder {
  color: #aaa;
}

.user-profile {
  display: flex;
  align-items: center;
  position: relative;
}

.total-xp {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #ffda44;
  color: black;
  font-weight: bold;
  border-radius: 5px;
  font-size: 16px;
}

.profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: grey;
  margin-right: 10px;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.username {
  font-weight: bold;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 0;
  z-index: 1000;
  width: 250px;
  height: calc(100vh - 60px);
  display: none;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: slide-in 0.3s forwards;
}

.dropdown-menu.show {
  display: flex;
}

.dropdown-menu button, .dropdown-menu a {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: black;
  text-decoration: none;
}

.dropdown-menu button i, .dropdown-menu a i {
  margin-right: 10px;
}

.dropdown-menu button:hover, .dropdown-menu a:hover {
  background-color: #f0f0f0;
}

.dropdown-footer {
  margin-top: auto;
  padding: 10px 20px;
  border-top: 1px solid #ccc;
  text-align: center;
  color: black;
}

.dropdown-footer a {
  color: black;
  text-decoration: none;
  display: block;
  margin: 5px 0;
}

.dropdown-footer a:hover {
  text-decoration: underline;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Add these styles for mobile responsiveness */
@media (max-width: 768px) {
  nav {
    display: none; /* Hide the nav menu on mobile */
  }

  .mobile-menu-button {
    display: block; /* Show the mobile menu button */
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }

  .mobile-dropdown-menu {
    display: none; /* Hide the dropdown menu by default */
    position: absolute;
    top: 60px; /* Position it below the header */
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 0;
    z-index: 1000;
    width: 100%; /* Full width */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .mobile-dropdown-menu.show {
    display: block; /* Show the dropdown menu when toggled */
  }

  .mobile-dropdown-menu a {
    display: block;
    padding: 10px 20px;
    color: black;
    text-decoration: none;
    font-size: 16px;
  }

  .mobile-dropdown-menu a:hover {
    background-color: #f0f0f0;
  }
}

@media (min-width: 769px) {
  .mobile-menu-button {
    display: none; /* Hide the mobile menu button on larger screens */
  }

  .mobile-dropdown-menu {
    display: none; /* Ensure the mobile dropdown menu is hidden on larger screens */
  }
}
