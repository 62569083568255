.dashboard {
  padding: 20px;
}

.dashboard h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.dashboard-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Ensure sections wrap on smaller screens */
}

.left-section {
  flex: 1;
  margin-right: 20px;
}

.right-section {
  flex: 2; /* Allow right section to take more space */
}

.section,
.chart-section {
  margin-bottom: 20px;
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section h2,
.chart-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.section ul {
  list-style-type: none;
  padding: 0;
}

.section ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.section ul li:last-child {
  border-bottom: none;
}

.chart {
  height: 300px; /* Set a fixed height for the chart container */
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .left-section, .right-section {
    flex: 1 1 100%;
    margin-right: 0;
  }

  .chart {
    height: 250px; /* Adjust the height for smaller screens */
  }
}
