/* Settings.css */
.settings {
    padding: 20px;
}

.settings h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

.time-allocation {
    background-color: #f8f8f8;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.school-extracurricular {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
}

.slider-container {
    margin-bottom: 20px;
}

.slider-container label {
    display: block;
    margin-bottom: 10px;
}

.slider-container input[type="range"] {
    width: 100%;
}

.subject-sliders-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.subject-sliders {
    flex: 2;
    margin-right: 20px; /* Space between columns */
}

.subject-sliders .slider-container {
    margin-bottom: 30px; /* Increased margin between slides */
}

.total-allocation {
    flex: 1;
    font-weight: bold;
    color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notice {
    font-weight: normal;
    color: black;
}

.notice.green {
    color: green;
}
