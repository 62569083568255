.discover {
  display: flex;
  height: calc(100vh - 60px);
}

.learn-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: bold;
  padding: 20px;
}

.topics-section {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
}

.topic-item {
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  font-size: 18px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  animation: float 6s ease-in-out infinite;
}

.topic-item:nth-child(2n) {
  animation-duration: 4s;
}

.topic-item:nth-child(3n) {
  animation-duration: 5s;
}

.topic-item:nth-child(4n) {
  animation-duration: 3s;
}

.topic-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.topic-item.selected {
  background-color: #4ECDC4;
  color: white;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
