.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  position: relative;
}

.content-area {
  width: 75%;
  height: 75vh;
  max-width: 720px; /* Updated max-width for better readability */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  padding: 6px 12px;
  border-radius: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.tag:nth-child(5n+1) { background-color: #FF6B6B; }
.tag:nth-child(5n+2) { background-color: #4ECDC4; }
.tag:nth-child(5n+3) { background-color: #45B7D1; }
.tag:nth-child(5n+4) { background-color: #FFA07A; }
.tag:nth-child(5n+5) { background-color: #98D8C8; }

.menu-container {
  position: relative;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 28px;
  color: #333;
}

.menu-options {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 0;
  z-index: 1000;
  min-width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.menu-options button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.menu-options button:hover {
  background-color: #f0f0f0;
}

.main-content {
  flex-grow: 1;
  padding: 20px 40px;
  overflow-y: auto;
  font-size: 18px;
}

.main-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.submit-button-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px; /* Add this line to increase the spacing below the button */
}

.submit-button {
  background-color: #ffda44;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #3ba892;
}

.bottom-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #e0e0e0;
  min-height: 60px;
}

.action-buttons,
.help-buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
}

.action-buttons button,
.help-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 5px 10px; /* Adjust margin for better spacing on smaller screens */
  font-size: 24px;
  color: #333;
  transition: color 0.3s ease;
}

.action-buttons button:hover,
.help-buttons button:hover {
  color: #0066cc;
}

.help-buttons button {
  font-size: 16px;
  padding: 8px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8;
  transition: background-color 0.3s ease;
}

.help-buttons button:hover {
  background-color: #e8e8f8;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 36px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
  z-index: 1000; /* Ensure buttons are above other content */
}

.nav-button:hover {
  color: #0066cc;
}

.nav-button.left {
  left: calc(50% - 410px); /* Positioned to the left of the content area */
}

.nav-button.right {
  right: calc(50% - 410px); /* Positioned to the right of the content area */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.answer-options {
  list-style-type: none;
  padding: 0;
}

.answer-option {
  background-color: #f0f0f0;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 12px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
}

.answer-option:hover {
  background-color: #e0e0e0;
}

.answer-option.selected {
  background-color: #d4edda;
  border-color: #28a745;
  color: #155724;
}

.true-false-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.true-false-options .answer-option {
  flex: 1;
  max-width: 200px;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .nav-button.left {
    left: 10px; /* Adjust for smaller screens */
  }

  .nav-button.right {
    right: 10px; /* Adjust for smaller screens */
  }

  .content-area {
    width: 90%;
    height: auto;
    padding: 20px; /* Add padding for smaller screens */
  }
}

@media (max-width: 768px) {
  .content-area {
    width: 90%;
    height: auto;
  }

  .top-bar, .bottom-bar {
    padding: 10px;
  }

  .main-content {
    padding: 10px 20px;
  }

  .nav-button {
    font-size: 24px;
  }

  .answer-option {
    padding: 10px;
    font-size: 14px;
  }

  .true-false-options {
    flex-direction: column;
    gap: 10px;
  }

  .true-false-options .answer-option {
    width: 100%;
  }

  .action-buttons button,
  .help-buttons button {
    font-size: 20px;
    margin: 5px;
  }

  .nav-button.left {
    left: 10px; /* Adjust for smaller screens */
  }

  .nav-button.right {
    right: 10px; /* Adjust for smaller screens */
  }
}

.share-options {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -50px; /* Adjust this as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  padding: 10px;
  z-index: 1000;
}

.share-options button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  font-size: 24px;
  color: #333;
  transition: color 0.3s ease;
}

.share-options button:hover {
  color: #0066cc;
}
